<template>
    <div class="member">



        <div class="content">
            <!-- 顶部 -->
            <Header titleName="创新案例提报" />

            <!-- 轮播 -->
            <div class="banner">
                <!-- <img class="empty_img" v-if="!data.bannerObj.filePath" src="@/assets/images/empty_img.png" alt=""> -->
                <img class="banner_img" src="@/assets/images/guarantee/msg_banner.png" alt="">
            </div>

            <div class="news">
                <div class="btn_view">
                    <div class="btn_view_title">
                        <img src="@/assets/images/guarantee/btn_title_bg2.png" alt="">
                        <div class="text"> <span class="cred">投稿</span>资料上传</div>
                    </div>
                </div>


                <div class="items space_between align_items_center">
                    <div style="">
                        <div class="bt">*</div>所属办事处
                    </div>
                    <div class="justify_content_end align_items_center" @click="officeListOpen()">
                        <span v-if="data.postData.officeId" style="color: #333;">{{ data.postData.officeTitle }}</span>
                        <span style="color: #ACACAC;" v-else>请选择所属办事处</span>
                        <img class="right" src="@/assets/images/right.png" alt="">
                    </div>
                </div>
                <div class="items space_between align_items_center">
                    <div style="">
                        <div class="bt">*</div>所在单位
                    </div>
                    <van-field v-model="data.postData.unit" :input-align="'right'" placeholder="请输入所在单位全称" />
                </div>
                <div class="items space_between align_items_center">
                    <div style="">
                        <div class="bt">*</div>联系人
                    </div>
                    <van-field v-model="data.postData.person" :input-align="'right'" placeholder="请输入投稿联系人" />
                </div>
                <div class="items space_between align_items_center">
                    <div style="">
                        <div class="bt">*</div>联系方式
                    </div>
                    <van-field type="number" v-model="data.postData.phone" :input-align="'right'" placeholder="请输入投稿人联系电话" />
                </div>
                <div class="items space_between align_items_center">
                    <div style="">
                        <div class="bt">*</div>类型
                    </div>
                    <div class="justify_content_end align_items_center" @click="typeListOpen()">
                        <span v-if="data.postData.type" style="color: #333;">{{ data.postData.typeName }}</span>
                        <span style="color: #ACACAC;" v-else>请选择类型</span>
                        <img class="right" src="@/assets/images/right.png" alt="">
                    </div>

                </div>
                <div class="items space_between align_items_center">
                    <div style="">备注</div>
                    <van-field v-model="data.postData.briefl_name" :input-align="'right'" placeholder="请输入备注" />
                </div>
                <div class="items" style="border: none;">
                    <div style="margin-bottom: .4rem;">
                        <div class="bt">*</div>上传附件
                    </div>
                    <van-uploader :accept="'.doc,.docx,.pdf'" :after-read="afterRead1" style="margin-bottom: .4rem;">
                        <div class="uploadview">
                            <img src="@/assets/images/guarantee/upload.png" alt="">
                            <div style="font-size: .4rem;font-weight: 600;color: #333;margin-bottom: .1333rem;">上传文件
                            </div>
                            <div style="font-size: .2933rem;color: #666;">请上传Word，PDF格式的文件</div>
                        </div>
                    </van-uploader>
                    <div class="fileList" v-for="(item, index) in data.fileList" :key="index">

                        <div class="align_items_center">
                            <img src="@/assets/images/guarantee/pdf.png" v-if="item.fileExt.toLowerCase() == 'pdf'"
                                class="icon" alt="">
                            <img src="@/assets/images/guarantee/word.png" v-else class="icon" alt="">
                            <div>

                                <div class="name">{{ item.fileName.split('.')[0] }}</div>
                                <div style="height: .25rem;"></div>
                            </div>

                        </div>
                        <img src="@/assets/images/guarantee/close.png" class="close" alt="" @click="delFile(index)">
                    </div>
                </div>

                <div style="font-size: .5067rem;font-weight: 600;margin-bottom: .4rem;"> <span
                        class="cred">投稿</span><span class="c3">规则说明</span></div>
                <div style="color: #888;line-height: .8rem;font-size: .3467rem;padding-bottom: .3rem;">
                    <div class="flex">
                        <p class="tag">1.</p>
                        <p>案例内容以中互字【2024】53号文件一中国职工保险互助会关于征集职工互助保障创新案例的通知相关要求提报。</p>
                    </div>
                    <div class="flex">
                        <p class="tag">2.</p>
                        <p>投稿上传附件Word，PDF格式。</p>
                    </div>
                </div>
            </div>

        </div>


        <div class="footer_btn">
            <div @click="submit">提交</div>
        </div>
        <!-- 类型弹窗 -->
        <div class="jj_dialog" v-if="data.typeListshow">
            <div class="dialog_box">
                <div class="dialog_header space_between align_items_center">
                    <div @click="data.typeListshow = false">取消</div>
                    <div class="f35 fw6">选择类型</div>
                    <div class="cred fw6" @click="typeSelect()">确定</div>
                </div>
                <div class="list">
                    <div v-for="(item, index) in data.typeList" :key="index"
                        :class="item.type == data.newTypeObj.type ? 'active' : ''" @click="typeChange(item)">{{
                            item.typeName }}</div>
                </div>
            </div>
        </div>
        <!-- 所在办事处弹窗 -->
        <div class="jj_dialog" v-if="data.officeListshow">
            <div class="dialog_box">
                <div class="dialog_header space_between align_items_center">
                    <div @click="data.officeListshow = false">取消</div>
                    <div class="f35 fw6">选择所属办事处</div>
                    <div class="cred fw6" @click="officeSelect()">确定</div>
                </div>
                <div class="list">
                    <div v-for="(item, index) in data.chinaList" :key="index"
                        :class="item.id == data.newOfficeObj.id ? 'active' : ''" @click="officeChange(item)">{{
                            item.zhaiyao }}</div>
                </div>
            </div>
        </div>
        <!-- 底部tab切换 -->
        <!-- <Footer /> -->
    </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs, onMounted, computed } from "vue";
import Header from "../../components/Header.vue";
import { useRoute, useRouter } from 'vue-router'
import Api from "../../utils/api";
import { jsToCallApp } from '../../common/js/callApp1.0.0'
import { Toast } from 'vant';
const route = useRoute()
const router = useRouter()

let data = reactive({
    postData: {
        officeId: '',//所属办事处
        officeTitle: '',//所属办事处
        person: '',//联系人
        phone: '',//联系人
        unit: '',//所在单位
        type: '',//类型
        typeName: '',//类型
        remark: '',//备注
    },
    newOfficeObj: {
        zhaiyao: '',
        id: '',
    },
    newTypeObj: {
        typeName: '',
        id: '',
    },
    fileList: [],
    chinaList: [],
    officeListshow: false,
    typeListshow: false,
    typeList: [
        {
            typeName: '维护职工权益',
            type: 1,
        },
        {
            typeName: '新就业形态劳动者',
            type: 2,
        },
        {
            typeName: '智慧工会建设',
            type: 3,
        },
        {
            typeName: '宣传传播',
            type: 4,
        },
        {
            typeName: '其他工作',
            type: 5,
        },
    ]
});

onMounted(() => {
    getAllList()
    shareFn()
    console.log(window.location.origin)
});
// 中国职工保险互助专区列表
const getAllList = () => {
    Api.articleViewChannelService({ categoryId: 15 }).then(res => {
        if (res.status == 200) {
            data.chinaList = res.data
        }
    })
}
const officeChange = (item) => {
    data.newOfficeObj.zhaiyao = item.zhaiyao
    data.newOfficeObj.id = item.id
}
const officeSelect = () => {
    data.postData.officeTitle = data.newOfficeObj.zhaiyao
    data.postData.officeId = data.newOfficeObj.id
    data.officeListshow = false
}

const officeListOpen = () => {
    data.newOfficeObj.zhaiyao = data.postData.officeTitle
    data.newOfficeObj.id = data.postData.officeId
    data.officeListshow = true
}

const typeChange = (item) => {
    data.newTypeObj.typeName = item.typeName
    data.newTypeObj.type = item.type
}
const typeSelect = () => {
    data.postData.typeName = data.newTypeObj.typeName
    data.postData.type = data.newTypeObj.type
    console.log(data.postData)
    data.typeListshow = false
}

const typeListOpen = () => {
    data.newTypeObj.typeName = data.postData.typeName
    data.newTypeObj.type = data.postData.type
    data.typeListshow = true
}

const shareFn = () => {
    const jsonString = {
        shareUrl: window.location.href,//分享链接
        shareCorver: window.location.origin + '/logo.png',
        shareTitle: '中国职工保障互助会',
        shareDesc: '创新案例提报',//分享描述
    }
    openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}
const afterRead1 = (files, detail) => {
    // console.log(files.file)
    let tyList = ['pdf', 'doc', 'docx']
    let ty = files.file.name.split('.')[1].toLowerCase()
    console.log(files.file.name.split('.')[1].toLowerCase())
    const result = tyList.some(num => num == ty);
    if (!result) {
        Toast('请上传Word，PDF文件！');
        return
    }
    const formData = new FormData();
    formData.append("file", files.file);
    Api.Upload(formData).then(res => {
        data.fileList.push(res.data[0])
        console.log(res.data)
    })
}
const delFile = (i) => {
    data.fileList.splice(i, 1)
}
const submit = () => {
    let parmas = data.postData
    parmas.fileUrl = ''
    for (let i = 0; i < data.fileList.length; i++) {
        parmas.fileUrl = `${parmas.fileUrl}${i == 0 ? '' : '|'}${data.fileList[i].filePath}`
    }

    let verify = {
        officeId: '请选择所属办事处！',
        unit: '请输入所在单位全称！',//
        person: '请输入投稿联系人！',//
        phone: '请输入投稿人联系电话！',//联系人
        type: '请选择类型！',//
        fileUrl: '请上传文件！',
    }
    for (const key in verify) {
        if (!parmas[key]) {
            Toast(verify[key]);
            return
        }
    }
    let phoneReg = /^1[3456789]\d{9}$/;
    if (!phoneReg.test(parmas.phone)) {
        Toast('请输入正确手机号！');
        return
    }
    console.log(parmas)
    Api.submitinformation(parmas).then(res => {
        console.log(res)
        if (res.status == 200) {
            Toast('您的优秀案例提交成功！');
            data.postData = {
                officeId: '',//所属办事处
                officeTitle: '',//所属办事处
                person: '',//联系人
                phone: '',//联系人
                unit: '',//所在单位
                type: '',//类型
                typeName: '',//类型
                remark: '',//备注
            }
            data.fileList = []
        }
    })
}

function openAppFunction(type, jsonData) {
    const useTimeOut = setTimeout(() => {
        jsToCallApp(
            {
                type: type,
                ...jsonData,
            },
            (data) => {
                console.log(type, data)
            },
        )
        clearTimeout(useTimeOut);
    }, 300);
}



</script>

<style lang='less' scoped>
/deep/ .van-tabs__wrap {
    border-radius: 10px;
}

::v-deep input.van-field__control::-webkit-input-placeholder {
    color: #ACACAC;
    font-size: .4rem;
}


.member {
    display: flex;
    flex-flow: column;
    height: 100%;
    font-size: 12px;

    .dialog_box {
        border-radius: .5067rem .5067rem 0px 0px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        min-height: 4rem;
        background-color: #fff;
        padding-bottom: .5rem;

        .list {
            max-height: 9.3333rem;
            overflow-y: scroll;

            >div {
                height: 1.3067rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .3867rem;
                border-top: .0133rem #E7E7E8 solid;
            }

            .active {
                background-color: #F5F5F5;
            }
        }

        .dialog_header {
            padding: 0 1.0933rem;
            font-size: .3867rem;
            height: 1.7333rem;

            .f35 {
                font-size: .4667rem;
            }
        }
    }

    .content {
        flex: 1;
        overflow-y: auto;
        padding-bottom: 2.4rem;

        p {
            margin: 0;
        }

        /deep/ .van-uploader {
            width: 100% !important;
        }

        /deep/ .van-uploader__input-wrapper {
            width: 100% !important;
        }

        .uploadview {
            width: 100% !important;
            height: 3.8133rem;
            background: rgba(216, 0, 16, 0.02);
            border-radius: .1333rem;
            border: .0133rem dotted #D80010;
            text-align: center;
            padding-top: .84rem;

            img {
                width: 1.1067rem;
            }
        }

        .fileList {
            width: 100% !important;
            height: 1.4133rem;
            border-radius: .1333rem;
            border: .0133rem dotted #ACACAC;
            margin-bottom: .4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 .32rem;

            .icon {
                width: .6933rem;
                margin-right: 10px;
            }

            .name {
                width: 5.6rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #666666;
            }

            .close {
                width: .3067rem;
            }
        }

        .items {
            border-bottom: .0133rem solid #ECECEC;
            padding: .3733rem 0;
            font-size: 0.4rem;

            .van-cell {
                padding: 0;
            }

            >div {
                width: calc(100% - 2rem);

                &:first-child {
                    width: 2rem;
                }
            }

            .right {
                width: .16rem;
                height: .28rem;
                margin-left: .2rem;
            }

            .bt {
                color: #D80010;
            }

            .btns {
                display: flex;

                >div {
                    width: 2.56rem;
                    height: .72rem;
                    background-color: #f5f5f5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: .16rem;
                    border-radius: .0533rem;
                }

                img {
                    width: .4667rem;
                    margin-right: .1067rem;
                }

                .active {
                    background-color: #E61D18;
                    color: #fff;
                }
            }

            >div {
                &:first-child {
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                }
            }
        }


        .banner {
            width: 100%;
            margin-bottom: .3333rem;
            height: 5.3333rem;
            text-align: center;
            background-color: rgba(240, 240, 240);

            .banner_img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .empty_img {
                width: 1.6rem;
                height: 1.6rem;
                margin-top: 2rem;
            }
        }

        .right_img {
            width: .16rem;
        }



        .news {
            background-color: #fff;
            padding: 0 .4rem;
            margin: 0 .4rem;
            border-radius: .1333rem;
            margin-bottom: .2667rem;

            .news_view_title {
                width: 4.92rem;
                height: 1.2rem;
                position: relative;
                margin: 0 auto;
                font-size: .4933rem;
                font-weight: 600;
                margin-bottom: .4rem;

                .news_title_bg {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: -0.0667rem;
                }

                .text {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    position: relative;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .news_list {
                margin-top: .2rem;

                >div {
                    padding: .4rem 0;
                    border-bottom: .0133rem #ececec solid;

                    &:last-child {
                        border: none;
                    }

                    img {
                        width: 3.0667rem;
                        height: 2.3067rem;
                        object-fit: cover;
                        margin-right: .3733rem;
                        border-radius: .1333rem;
                    }
                }
            }

            .right_box {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 2.3067rem;
                width: calc(100% - 3.5rem);
            }
        }

        .cred {
            color: #D80010;
            font-weight: bold;
        }

        .msg {
            background-color: #fff;
            padding: .5067rem .4rem;
            padding-top: .1rem;
            margin-bottom: .2667rem;

            .dt {
                width: 100%;

                img {
                    width: 100%;
                }
            }

            p {
                text-indent: .7rem;
                font-size: .4rem;
                color: #707070;
                line-height: .8267rem;

            }
        }


        .btn_view {
            width: 100%;
            margin: 0 auto;

            .btn_view_title {
                width: 7.1467rem;
                height: 1.2rem;
                position: relative;
                margin: 0 auto;
                font-size: .4933rem;
                font-weight: 600;
                margin-bottom: .4rem;

                img {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: -0.0667rem;
                }

                .text {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    padding-top: .25rem;
                    position: relative;
                    z-index: 2;
                }
            }

            .btns {
                display: flex;
                justify-content: space-between;
            }

            .btn {
                width: 3.9733rem;
                height: 1.6933rem;
                border-radius: .1333rem;
                flex: 4;
                font-size: .40rem;
                text-align: center;
                margin: 0 .2rem;
                color: #333333;

                img {
                    width: 1.5rem;
                    margin-bottom: .2rem;
                }
            }

            .pink {
                background: linear-gradient(180deg, rgba(254, 180, 179, .39) 0%, rgba(254, 248, 223, .39) 100%);
            }

            .blue {
                background: linear-gradient(180deg, rgba(201, 243, 242, .39) 0%, rgba(245, 255, 253, .39) 100%);
            }
        }



    }

    .footer_btn {
        height: 2.3333rem;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: #fff;
        box-shadow: 0px -0.1333rem .08rem 1px rgba(0, 0, 0, 0.03);
        z-index: 2;
        >div {
            width: 9.3867rem;
            height: 1.1333rem;
            background: #E61D18;
            border-radius: .1067rem;
            margin: .2667rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .4rem;
            color: #fff;
        }
    }
}
</style>